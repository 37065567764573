import React, { useMemo } from "react";

import { NavigationTabs } from "../../../core/NavigationTabs/NavigationTabs";

import { SingleCapitalCall } from "../data/SingleCapitalCall";
import { SingleDistribution } from "../data/SingleDistribution";

import { CapitalCalls } from "../CapitalCalls/CapitalCalls";
import { Distributions } from "../Distributions/Distributions";
import { EmptyDistributions } from "../Distributions/EmptyDistributions";
import { BankAccounts } from "../bankAccounts/BankAccounts";
import { SingleBankAccount } from "../data/SingleBankAccount";

type Props = {
  capitalCalls: ReadonlyArray<SingleCapitalCall>;
  distributions: ReadonlyArray<SingleDistribution>;
  bankAccounts?: ReadonlyArray<SingleBankAccount>;
  enableAddBankAccount?: boolean;
};

/**
 * The main transactions view component.
 */
export const TransactionsView: React.FC<Props> = ({
  capitalCalls,
  distributions,
  bankAccounts,
  enableAddBankAccount,
}) => {
  const tabs = useMemo(
    () => [
      {
        route: "capital-calls" as const,
        displayName: "Capital calls",
        component: <CapitalCalls capitalCalls={capitalCalls} />,
        badgeContent: capitalCalls.length > 0 ? capitalCalls.length : null,
      },
      {
        route: "distributions" as const,
        displayName: "Distributions",
        component:
          distributions.length > 0 ? (
            <Distributions distributions={distributions} />
          ) : (
            <EmptyDistributions />
          ),
        badgeContent: distributions.length > 0 ? distributions.length : null,
      },
      ...(bankAccounts
        ? [
            {
              route: "bank-accounts" as const,
              displayName: "Bank Accounts",
              component: (
                <BankAccounts
                  key="BankAccounts"
                  bankAccounts={bankAccounts}
                  enableAddBankAccount={enableAddBankAccount}
                />
              ),
              badgeContent: null,
            },
          ]
        : []),
    ],
    [capitalCalls, distributions, bankAccounts, enableAddBankAccount],
  );

  return <NavigationTabs defaultTab="capital-calls" tabs={tabs} />;
};
