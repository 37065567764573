/**
 *
 */
export const generateCapitalCallFundAggregationId = (
  fundName: string,
  currency: string,
): string => `${fundName}#${currency}`;

/**
 *
 */
export const generateDistributionFundAggregationId = (
  fundName: string,
  currency: string,
  taxNature: [string, boolean],
): string =>
  `${fundName}#${currency}#${taxNature[0]}#${taxNature[1] ? "R" : "NR"}`;

/**
 * Given an array of funds names, format them into a single string.
 */
export const formatAggregatedFundName = (funds: Set<string>): string => {
  const fundsArray = Array.from(funds);

  return fundsArray.length <= 2
    ? fundsArray.join(", ")
    : `${fundsArray[0]}, ${fundsArray[1]} and ${fundsArray.length - 2} more`;
};

/**
 * Given a tax nature and a boolean indicating if the tax is recallable, format
 * the tax nature into a human readable string.
 */
export const formatTaxNature = (
  nature: string,
  isRecallable: boolean,
): string => {
  const label = nature
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/^\w/, (c) => c.toUpperCase());

  return isRecallable ? `${label} (Recallable)` : label;
};
