import React from "react";
import { Typography } from "@mui/material";
import { useHeritageV2Palette } from "../../../../utils/hooks/useHeritageV2Palette";
import { Box } from "../Box/Box";
import { heritageV2Palette } from "../../../../mui/heritageV2Palette";
import { match } from "ts-pattern";

type PublicChipProps = {
  children: string;
};

export type BaseChipProps = PublicChipProps & {
  backgroundColor: keyof typeof heritageV2Palette;
  textColor: keyof typeof heritageV2Palette;
  transparentBackground?: boolean;
  size?: "small" | "base";
  radius?: "normal" | "rounded";
};

/**
 * The core chip component, or commonly known as a "Badge".
 */
export const Chip: React.FC<BaseChipProps> = ({
  children,
  backgroundColor,
  textColor,
  transparentBackground,
  radius = "normal",
  size = "base",
}) => {
  const palette = useHeritageV2Palette();

  return (
    <Box
      px={match(size)
        .with("base", () => 0)
        .with("small", () => "6px")
        .exhaustive()}
      py={match(size)
        .with("base", () => "4px")
        .with("small", () => "2px")
        .exhaustive()}
      bgcolor={palette[backgroundColor] + (transparentBackground ? "30" : "")}
      borderRadius={match(radius)
        .with("normal", () => "4px")
        .with("rounded", () => "12px")
        .exhaustive()}
      display="inline-grid"
      maxWidth="100%"
    >
      <Typography
        variant={match(size)
          .with("small", () => "body2" as const)
          .with("base", () => "formLabel" as const)
          .exhaustive()}
        color={palette[textColor]}
        className="truncate"
        maxWidth="100%"
      >
        {children}
      </Typography>
    </Box>
  );
};

/**
 * A chip with a transparent background.
 */
export const OpaqueChip: React.FC<PublicChipProps> = ({ children }) => {
  return (
    <Chip
      backgroundColor="invariantWhite"
      textColor="invariantWhite"
      transparentBackground
    >
      {children}
    </Chip>
  );
};

/**
 * A chip representing an asset attribute.
 */
export const AttributeChip: React.FC<PublicChipProps> = ({ children }) => {
  return (
    <Chip backgroundColor="chart500" textColor="invariantWhite">
      {children}
    </Chip>
  );
};

/**
 * A chip representing a positive status.
 */
export const PositiveChip: React.FC<PublicChipProps> = ({ children }) => {
  return (
    <Chip backgroundColor="statusPositive" textColor="invariantWhite">
      {children}
    </Chip>
  );
};

/**
 * A chip used to identify draft quarters.
 */
export const DraftQuarterChip: React.FC<PublicChipProps> = ({ children }) => {
  return (
    <Chip size="small" backgroundColor="neutral500" textColor="invariantWhite">
      {children}
    </Chip>
  );
};
