import React, { useCallback, useMemo, useState } from "react";

import { SingleAsset } from "../data/SingleAsset";
import { Dialog } from "../../../core/v2/Dialog/Dialog";
import { Typography } from "@mui/material";
import { Input } from "../../../core/v2/Input/Input";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { PendingAllocation } from "../data/PendingAllocation";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { P, match } from "ts-pattern";
import { useInvestorCreateAllocationFormMutation } from "../../../../generated/urql";
import { isDefined } from "../../../../lib/utils/types";
import { useInvestor } from "../../../../hooks/useInvestor";
import { useNotifications } from "../../../../utils/notifications";
import { Box } from "../../../core/v2/Box/Box";

const localFormatCurrency = U_Show.currencyWebAppGeneric("USD").show;

const requestAllocationFormSchema = z.object({
  amount: z.coerce.number().positive(),
});

type RequestAllocationForm = z.infer<typeof requestAllocationFormSchema>;

type Props = {
  open: boolean;
  onClose: () => void;
  asset: SingleAsset;
  pendingAllocation?: PendingAllocation;
};

/**
 * This component will allow the user to request an allocation for a given asset.
 */
export const RequestAllocationDialog: React.FC<Props> = ({
  open,
  onClose,
  asset,
  pendingAllocation,
}) => {
  const investor = useInvestor();
  const [requestStatus, submit] = useInvestorCreateAllocationFormMutation();
  const { successNotification, errorNotification } = useNotifications();

  const currentAmount = useMemo(() => {
    return pendingAllocation?.amountUsd ?? 0;
  }, [pendingAllocation]);

  const minAmount = useMemo(() => {
    return asset?.minimumParticipationUsd ?? 0;
  }, [asset]);

  const stableDefaultValues = useMemo(() => {
    const amount = pendingAllocation?.amountUsd;

    return amount
      ? {
          amount,
        }
      : undefined;
  }, [pendingAllocation]);

  const {
    handleSubmit: handleFormSubmit,
    control,
    formState: { isValid },
    reset,
  } = useForm<RequestAllocationForm>({
    mode: "onChange",
    resolver: zodResolver(
      requestAllocationFormSchema
        .refine(({ amount }) => amount !== currentAmount, {
          message: "Amount should be different than " + currentAmount,
        })
        .refine(({ amount }) => amount >= minAmount, {
          message: "Amount should be greater than " + minAmount,
        }),
    ),
    values: stableDefaultValues,
  });

  const [confirmedAmount, setConfirmedAmount] = useState<number | undefined>();

  const isUpdating = !!pendingAllocation;

  const minimumRequired = useMemo(
    () =>
      asset?.minimumParticipationUsd
        ? `Minimum ${localFormatCurrency(asset.minimumParticipationUsd)}`
        : undefined,
    [asset],
  );

  const handleSubmit = useCallback(() => {
    // If we are in the confirmation step we are going
    // to actually execute the mutation.
    if (isDefined(confirmedAmount) && !requestStatus.fetching && investor?.id)
      submit({
        input: {
          amountUsd: confirmedAmount,
          assetId: asset.id,
          investorId: investor.id,
          pendingAllocationId: pendingAllocation?.id,
        },
      })
        .then(() => successNotification("Allocation request sent"))
        .catch(() => errorNotification("Something went wrong"))
        .finally(onClose);
    // If we are still in the first step we set the `confirmedAmount`
    // to the value of the form and we wait for the user to confirm
    // the allocation.
    else
      handleFormSubmit((data) => {
        setConfirmedAmount(data.amount);
      })();
  }, [
    confirmedAmount,
    investor,
    asset,
    pendingAllocation,
    submit,
    handleFormSubmit,
    requestStatus,
    onClose,
    successNotification,
    errorNotification,
  ]);

  const handleClear = useCallback(() => {
    setConfirmedAmount(undefined);
    reset();
  }, [reset]);

  return (
    <Dialog
      title={match([isUpdating, confirmedAmount])
        .with([true, P.nullish], () => "Edit request allocation for")
        .with([false, P.nullish], () => "Request allocation for")
        .with([P._, P.not(P.nullish)], () => "Confirm allocation request?")
        .exhaustive()}
      titleHighlighted={!confirmedAmount ? asset.name : undefined}
      open={open}
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSubmit}
      cancelButtonText="Cancel"
      submitButtonText={
        !confirmedAmount ? "Request allocation" : "Confirm request"
      }
      submitButtonDisabled={!isValid}
      minWidth={500}
      onExited={handleClear}
    >
      {!confirmedAmount ? (
        <>
          <Typography>How much would you like to allocate?</Typography>

          <Box mt={2}>
            <Controller
              control={control}
              name="amount"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Amount in $"
                  helperText={minimumRequired}
                  type="number"
                  value={field.value?.toString()}
                />
              )}
            />
          </Box>
        </>
      ) : (
        <Typography>
          You are requesting to allocate{" "}
          <strong>{localFormatCurrency(confirmedAmount)}</strong> for{" "}
          <strong>{asset.name}</strong>.
        </Typography>
      )}
    </Dialog>
  );
};
