import React from "react";
import { IterableElement } from "type-fest";
import { PortfolioRouteQuery } from "../../../../generated/urql";
import { useMemo } from "react";
import {
  getCommittedCapitalCalledPercent,
  getFirstCapitalCallDate,
} from "../../../../utils/allocations";
import * as U_Show from "@heritageholdings/lib-commons-finance/lib/show";
import { StatBox } from "../../../core/v2/StatBox/StatBox";
import Grid, { Grid2Props } from "@mui/material/Unstable_Grid2";

type Props = {
  quarterPortfolioSituation: IterableElement<
    PortfolioRouteQuery["investor"]["portfolioSituation"]
  >;
  isDraft?: boolean;
};

/**
 * The top statistics in the portfolio overview page.
 */
export const PortfolioOverviewStats: React.FC<Props> = ({
  quarterPortfolioSituation,
  isDraft,
}) => {
  const { totals, byAllocation } = useMemo(
    () => quarterPortfolioSituation,
    [quarterPortfolioSituation],
  );

  const firstCapitalCallDate = useMemo(
    () => getFirstCapitalCallDate(byAllocation),
    [byAllocation],
  );

  const committedCapitalCalledPercent = useMemo(
    () =>
      getCommittedCapitalCalledPercent(
        totals.paidInUsd,
        totals.allocationAmountUsd,
      ),
    [totals],
  );

  const formattedIrr = useMemo(
    () =>
      U_Show.irrWithNM({
        profit: totals.profitUsd,
        mom: totals.mom,
        firstCapitalCallDate,
        committedCapitalCalledPercent,
      }).show(totals.irr),
    [totals, firstCapitalCallDate, committedCapitalCalledPercent],
  );

  const showProfitAndDistributed = useMemo(
    () => totals.distributedUsd > 0,
    [totals.distributedUsd],
  );

  const commonStatContainerProps: Grid2Props = useMemo(
    () =>
      showProfitAndDistributed
        ? {
            sm: 12,
            md: 6,
            xl: 4,
          }
        : {
            sm: 12,
            md: 6,
            xl: 6,
          },
    [showProfitAndDistributed],
  );

  return (
    <Grid container spacing={2}>
      <Grid {...commonStatContainerProps}>
        <StatBox
          value={U_Show.currencyWebAppGeneric("USD").show(
            totals.allocationAmountUsd,
          )}
          title="Committed Capital"
          subTitle={`Paid-in: ${U_Show.percentSingleDecimal.show(
            totals.paidInUsd / totals.allocationAmountUsd,
          )}`}
        />
      </Grid>

      <Grid {...commonStatContainerProps}>
        <StatBox
          value={U_Show.currencyWebAppGeneric("USD").show(totals.navUsd)}
          title={isDraft ? "NAV (Est.)" : "NAV"}
          subTitle="Net Asset Value"
          highlightValue
        />
      </Grid>

      {showProfitAndDistributed && (
        <Grid {...commonStatContainerProps}>
          <StatBox
            value={U_Show.currencyWebAppGeneric("USD").show(totals.profitUsd)}
            title="Profit"
            subTitle="Total Profit"
          />
        </Grid>
      )}

      {showProfitAndDistributed && (
        <Grid {...commonStatContainerProps}>
          <StatBox
            value={U_Show.currencyWebAppGeneric("USD").show(
              totals.distributedUsd,
            )}
            title="Distributed"
            subTitle="Total distributed"
          />
        </Grid>
      )}

      <Grid {...commonStatContainerProps}>
        <StatBox
          value={formattedIrr}
          title={isDraft ? "IRR (Est.)" : "IRR"}
          subTitle="INTERNAL RATE OF RETURN"
        />
      </Grid>

      <Grid {...commonStatContainerProps}>
        <StatBox
          value={U_Show.multiple1WithoutSuffix.show(totals.mom)}
          symbol="×"
          title={isDraft ? "MoM (Est.)" : "MoM"}
          subTitle="Multiple of Money"
        />
      </Grid>
    </Grid>
  );
};
